import bus from '@/bus'
import lookService from '@/services/queries/lookQueries'
import LookCards from '@/components/Shared/LookCards.vue'

export default {
  components: {
    LookCards
  },

  props: {
    id: {
      required: true
    }
  },

  data() {
    return {
      looks: []
    }
  },

  metaInfo() {
    return {
      title: this.$t('agent.clients.looks.list.pageTitle')
    }
  },

  created() {
    this.loadLooks()

    bus.$on('loadLooksRequested', this.loadLooks)
  },

  methods: {
    loadLooks() {
      let params = {
        sorting: 'time_created' + '{desc}',
        is_draft: false,
        client_id: this.id
      }

      lookService.get('me', params).then(data => {
        this.looks = data.items.map((look) => {
          return {
            ...look,
            style: this.getStyle(look)
          }
        })
      })
    },

    getStyle(look) {
      return {
        background: look.previewUrl ? `url(${look.previewUrl}) center center / contain no-repeat` : 'none',
        'background-color': '#fff'
      }
    },

    createNewLook() {
      this.$router.push({
        name: 'studio',
        query: { clientId: this.id }
      })
    }
  }
}