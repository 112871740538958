import { render, staticRenderFns } from "./ClientsLooks.html?vue&type=template&id=1feb6caa&scoped=true&"
import script from "./ClientsLooks.js?vue&type=script&lang=js&"
export * from "./ClientsLooks.js?vue&type=script&lang=js&"
import style0 from "./ClientsLooks.scss?vue&type=style&index=0&id=1feb6caa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1feb6caa",
  null
  
)

export default component.exports