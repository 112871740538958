<template>
  <div class="look-cards">
    <look-card class="look"
               v-for="look in looks"
               :key="look.id"
               :look="look"/>
  </div>
</template>

<script>
  import LookCard from './LookCard/LookCard.vue'

  export default {
    props: {
      looks: {
        required: true,
        type: Array
      }
    },

    components: {
      LookCard
    }
  }
</script>

<style lang="scss" scoped>
  .look-cards {
    display: flex;
    flex-wrap: wrap;

    .look {
      margin: 10px;
    }
  }
</style>
